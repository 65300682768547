import { useEffect, useState } from 'react';
import {
  message, Modal, Table, Button,
} from 'antd';
import dayjs from 'dayjs';
import { queryCompanyLabel } from '@/service/user-service';
import moment from 'moment';
import PropTypes from 'prop-types';

const Index = ({ onClose }) => {
  const [data, setData] = useState({
    loading: false,
    list: [],
  });
  const [open, setOpen] = useState(false);

  const now = dayjs();
  const twoMonthsLater = now.add(2, 'month');
  const getExpiredLabel = async () => {
    const loginUser = localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData'))
      : {};
    if (loginUser?.userType !== 1) return;
    setData({
      list: [],
      loading: true,
    });
    const res = await queryCompanyLabel({});
    if (res?.success) {
      const filterList = res?.data?.filter((e) => {
        const endTime = dayjs(e.endTime);
        return endTime.isBefore(twoMonthsLater); // 包括已经到期的数据
      }) || [];
      setOpen(filterList.length > 0);
      if (filterList.length === 0) {
        onClose();
      }
      setData({
        list: filterList,
        loading: false,
      });
    } else {
      message.error(res?.message);
    }
  };

  useEffect(() => {
    getExpiredLabel();
  }, []);

  return (
    <Modal
      width={700}
      open={open}
      title="文档服务时间"
      onCancel={() => {
        onClose();
      }}
      footer={(
        <Button type="primary" onClick={() => onClose()}>
          我知道了
        </Button>
      )}
    >
      <Table
        pagination={false}
        dataSource={data.list}
        loading={data.loading}
        columns={[
          {
            title: '标签名称',
            dataIndex: 'labelName',
          },
          {
            title: '服务开始时间',
            dataIndex: 'startTime',
            render: (text) => text && moment(text).format('YYYY-MM-DD'),
          },
          {
            title: '服务到期时间',
            dataIndex: 'endTime',
            render: (text) => (
              <div
                style={{
                  color:
                    moment(text).valueOf() < new Date().getTime()
                      ? 'red'
                      : 'black',
                }}
              >
                {text && moment(text).format('YYYY-MM-DD')}
              </div>
            ),
          },
        ]}
      />
    </Modal>
  );
};

Index.propTypes = {
  onClose: PropTypes.func,
};

Index.defaultProps = {
  onClose: () => {},
};

export default Index;
