import Cookies from 'js-cookie';

/**
 * 清理前端缓存LocalStorage
 */
export const clearLocalStorage = () => {
  const reportDowload = localStorage.getItem('report-dowload');// 特殊情况 报表需要退出的时候保留缓存,这里不想一个一个清理localStorage了
  const customerIsShow = localStorage.getItem('customerIsShow');
  const wechatVisible = localStorage.getItem('wechatVisible');
  const contactListWidth = localStorage.getItem('contactListWidth');
  const p = localStorage.getItem('p');
  const customerMessageHeader = localStorage.getItem('customer-message-header');
  const customerColumns = localStorage.getItem('customerColumns');
  const seaColumns = localStorage.getItem('seaColumns');
  localStorage.clear();
  if (reportDowload) localStorage.setItem('report-dowload', reportDowload);
  if (p) localStorage.setItem('p', p);
  if (customerColumns) localStorage.setItem('customerColumns', customerColumns);
  if (seaColumns) localStorage.setItem('seaColumns', seaColumns);
  if (customerMessageHeader) localStorage.setItem('customer-message-header', customerMessageHeader);
  if (customerIsShow) localStorage.setItem('customerIsShow', customerIsShow);
  if (wechatVisible) localStorage.setItem('wechatVisible', wechatVisible);
  if (contactListWidth) localStorage.setItem('contactListWidth', contactListWidth);
};

/**
 * 退出登录清理cookie+localStorage
 * @param {*} url
 */

export const logout = async (url) => {
  Cookies.remove('userid');
  Cookies.remove('company');
  // 部分电脑之前登录过 导致还有这个，程序先清理下，后续这个代码可以删除（dialTimes）
  Cookies.remove('dialTimes');
  Cookies.remove('user', { path: '/', domain: 'yunkecn.com' });
  Cookies.remove('userToken', { path: '/', domain: 'yunkecn.com' });
  clearLocalStorage();
  if (url) {
    window.location.href = url;
  }
};

/**
 * 将百度座标转换为高德座标
 * @param {*} item
 * @return 高德坐标
 */
export const bd09ToGcj02 = (item) => {
  const xPi = (3.14159265358979324 * 3000.0) / 180.0;
  const x = item.longitude - 0.0065;
  const y = item.latitude - 0.006;
  const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * xPi);
  const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xPi);
  return {
    longitude: z * Math.cos(theta),
    latitude: z * Math.sin(theta),
  };
};

/**
 *密码校验
 */

export const isPasswordValid = (rule, val) => {
  if (!val) {
    return Promise.resolve();
  }
  let typesCount = 0;
  if (val.length < 8) {
    return Promise.reject(new Error('您输入密码格式错误，密码为8位以上字母+数字+大写字母+特殊字符组合，满足三组组合即可'));
  }
  if (/.*[0-9].*/.test(val)) {
    typesCount += 1;
  }
  if (/.*[a-z].*/.test(val)) {
    typesCount += 1;
  }
  if (/.*[A-Z].*/.test(val)) {
    typesCount += 1;
  }
  if (/.*[@#$%^&+=!].*/.test(val)) {
    typesCount += 1;
  }
  if (typesCount >= 3) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('您输入密码格式错误，密码为8位以上字母+数字+大写字母+特殊字符组合，满足三组组合即可'));
};
