import {
  Modal, message, Button,
} from 'antd';
import { useEffect, useState } from 'react';
import { CommonTableComponent } from 'yunkecom';
import { getDeviceExpireList, exportDeviceExpire } from '@/service/user-service';

import PropTypes from 'prop-types';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const ExpireModal = ({ onClose }) => {
  const loginUser = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const [data, setData] = useState({
    loading: false,
    total: 0,
    list: [],
  });
  const [open, setOpen] = useState(false);

  const loadData = async () => {
    if (loginUser?.userType !== 1) return;
    const response = await getDeviceExpireList(params);
    if (response.success) {
      setOpen(response.data.deviceExpireList.length > 0);
      setData({
        loading: false,
        total: response.data?.dataCount || 0,
        list: response.data.deviceExpireList,
      });
    } else {
      message.error(response.message);
    }
  };

  const handelChangeExport = async () => {
    // localStorage.setItem('expire', '1');
    // localStorage.setItem('hasClosedModals', 'true');
    onClose();
    const response = await exportDeviceExpire({ pageSize: 50000 });
    if (response.success) {
      Modal.confirm({
        title: '下载中心',
        content: response.message,
        okText: '去查看',
        cancelText: '取消',
        onOk: () => {
          if (window.self === window.top) {
            window.open('/cms/dowload');
          } else {
            window.location.href = '/cms/dowload';
          }
        },
      });
    } else {
      message.error(response.message);
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      setData({
        loading: false,
        total: 0,
        list: [],
      });
    };
  }, [params]);
  return (
    <Modal
      title="设备到期"
      open={open}
      footer={[
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <Button style={{ marginRight: 10 }} type="primary" onClick={handelChangeExport}>下载列表</Button>
          <Button
            type="primary"
            onClick={() => {
              onClose();
            }}
          >
            我知道了
          </Button>
        </div>,
      ]}
      onCancel={() => {
        onClose();
      }}
      width={710}
    >
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        尊敬的：
        {loginUser.realName}
        您好，您有
        {data.total}
        台手机设备即将到期，请您关注如下明细或者联系商务进行续费，避免给您业务带来不便
      </div>
      <CommonTableComponent
        rowKey="imei"
        dataSource={data.list}
        loading={data.loading}
        total={data.total}
        columns={[
          {
            title: '姓名',
            dataIndex: 'userName',
            width: 100,
          },
          {
            title: '手机号',
            dataIndex: 'account',
            width: 120,
          },
          {
            title: 'IMEI',
            dataIndex: 'imei',
            width: 200,
          },
          {
            title: '设备到期时间',
            dataIndex: 'dueTime',
            width: 120,
          },
        ]}
        pageIndex={params.page}
        pageSize={params.pageSize}
        onChange={(page, pageSize) => {
          setParams({
            page,
            pageSize,
          });
        }}
      />
    </Modal>
  );
};
ExpireModal.propTypes = propTypes;

export default ExpireModal;
