import React, { Suspense } from 'react';
import {
  ConfigProvider,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { BecomeDueModal } from 'yunkecom';
import { Capture, request } from 'yunkecom/dist/utils';
import { version } from '../package.json';
import 'intro.js/introjs.css';
import LayoutComponent from './pages/layout';
import commonRouterConfig from './routes/common-router';
import homeRouterConfig from './routes/home-router';
import cluesRouterConfig from './routes/clues-router';
import deviceRouterConfig from './routes/device-router';
import NotAccess from './pages/errorPages/notAccess';

const routerConfig = [
  ...commonRouterConfig,
  ...homeRouterConfig,
  ...cluesRouterConfig,
  ...deviceRouterConfig,
];
// request.setBaseURL('https://test.yunkecn.com');
request.setBaseURL('/');

let deploy = {};
const modules = import.meta.globEager('./ymls/*.yml');
const company = import.meta.env.VITE_APP_PRIVATE;
Object.keys(modules).forEach(async (el) => {
  if (el.includes(company)) {
    deploy = modules[el].default;
  }
});

if (import.meta.env.VITE_APP_PRIVATE === 'yunke' || import.meta.env.VITE_APP_PRIVATE === 'tanke') {
  const capture = new Capture({
    dsn: 'https://7d8b1baa519e550ad7d116a6fde118eb@sentry.yunkecn.com/17',
    release: version,
    env: import.meta.env.MODE,
  });
  window.capture = capture;
}

/**

/**
 * 根据菜单配置转换成路由组件
 */
function getRouteByConfig() {
  // TODO 这里要考虑初次登录没有localStorage的情况
  const menus = localStorage.getItem('permissions');
  let indexUrl = '/home/statistics';
  // 把所有服务器端没有设置的路由写在这里
  let permissions = [
    '/cms/authorize',
    '/cms/redirect',
    '/cms/auth/reg',
    '/cms/auth/login',
    '/cms/404',
    '/cms/500',
    '/cms/auth/forgotpass',
    '/cms/auth/announce',
    '/cms/auth/accountcheck',
    '/cms/auth/changephone',
    '/cms/dowload',
    '/cms/home/myaccount',
    '/cms/mission/detail',
    '/cms/mission/plan',
    '/cms/home/reportform/home/download',
    '/cms/home/intelligentAssistant',
    '/cms/home/reportform/wechat/wechatlist',
    '/cms/home/reportform/:module/detail',
    '/cms/home/reportform/:module/list',
    '/cms/clues/:project/:url',
    '/cms/clues/:project',
    '/cms/home/outboundList/:type',
    '/cms/home/electronicSales',
    '/cms/home/sipIntroduce',
    '/cms/home/studioCamera',
    '/cms/geofencing',
    '/cms/home/transferOutbound/:type',
    '/cms/home/OpenTransferOutbound',
    '/cms/home/pc/:url',
    '/cms/qualityTesting',
    '/cms/home/applyOutbound',
    '/cms/home/electronList',
    '/cms/import',
    '/cms/home/userDetail',
    '/cms/expenditureDetails',
    '/cms/billingRecord',
    '/cms/addBillingReacord',
    '/cms/riskNumber',
    '/cms/anitComplaint',
    '/cms/home/topuprecord',
    '/cms/numberCleanRecord',
    '/cms/settings/seasListDetail',
    '/cms/device/deviceOffLineStatic',
    '/cms/auth/resetpwd',
    '/cms/callStatistics',
    '/cms/callStatistics/:type',
    '/cms/device/geofencinglog',
    '/cms/device/geofencingsetup',
    '/cms/home/customerContactDetail',
    '/cms/taskCenter',
    '/cms/chat',
    '/cms/notifications',
    '/cms/convertText',
    '/cms/home/dataPanel',
    '/cms/device/phonescreenshot/:type',
    '/cms/device/devicestatistics/detailedList',
  ];

  if (menus) {
    const loginMenus = JSON.parse(menus);
    if (loginMenus[0] && loginMenus[0].url) {
      indexUrl = loginMenus[0].url.replace('/cms', '');
      permissions = permissions.concat(loginMenus.map((item) => {
        let c = [];
        if (item.children) {
          c = item.children.map((item1) => {
            let d = [];
            if (item1.children) {
              d = item1.children.map((item2) => item2.url);
            }
            return [item1.url, ...d];
          }).flat();
        }
        return [item.url, ...c];
      }).flat());
    }
  }

  const route = routerConfig.map(
    (config) => {
      if (localStorage.getItem('iframeIsMeun') === '2') {
        return (
          <Route
            key={config.key}
            path={config.path}
            exact={config.exact || false}
            render={(props) => (
              <config.component
                history={props.history}
                location={props.location}
                match={props.match}
                staticContext={props.staticContext}
                routes={config.routes}
                deploy={deploy[company][config.key]}
              />
            )}
          />
        );
      }
      if (permissions.indexOf(`/cms${config.path}`) >= 0) {
        return (
          <Route
            key={config.key}
            path={config.path}
            exact={config.exact || false}
            render={(props) => (
              <config.component
                history={props.history}
                location={props.location}
                match={props.match}
                staticContext={props.staticContext}
                routes={config.routes}
                deploy={deploy[company][config.key]}
              />
            )}
          />
        );
      }
      return (
        <Route
          key={config.key}
          path={config.path}
          exact={config.exact || false}
          component={NotAccess}
        />
      );
    },
  );
  // 把首页缺省重定向
  route.push(
    <Route
      exact
      key="home"
      path="/"
    >
      <Redirect to={indexUrl} />
    </Route>,
  );
  return route;
}

function App() {
  const search = new URLSearchParams(window.location.search);
  const isMeun = search.get('menu');
  const cookie = search.get('cookie');
  if (cookie) {
    sessionStorage.setItem('user', cookie);
  }
  if (isMeun) {
    localStorage.setItem('iframeIsMeun', isMeun);
  }
  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter basename="cms">
        {/* eslint-disable-next-line */}
        {/* <Provider {...allStore}> */}
        { localStorage.getItem('iframeIsMeun') === '2' ? (
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {/* 根据路由的配置信息 设置路由组件 */}
              {getRouteByConfig()}
              {/* <Route key="404" component={NotFound} /> */}
            </Switch>
          </Suspense>
        ) : (
          <LayoutComponent>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {/* 根据路由的配置信息 设置路由组件 */}
                {getRouteByConfig()}
                {/* <Route key="404" component={NotFound} /> */}
              </Switch>
            </Suspense>
          </LayoutComponent>
        )}
        {(localStorage.getItem('iframeIsMeun') === '2' || cookie || sessionStorage.getItem('user')) ? null : <BecomeDueModal />}

        {/* </Provider> */}
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
