import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import FetchJsonp from 'fetch-jsonp';
import {
  registerMicroApps,
  start,
  initGlobalState,
} from 'qiankun';
import { name, version } from '../package.json';
import './i18n';
import './tailwind.css';
import './public/css/main.moudle.less';
import App from './App';

let deploy = {};
// 子程序注册
const modules = import.meta.glob('./ymls/*.yml', { eager: true });
Object.keys(modules).forEach((el) => {
  if (el.includes(import.meta.env.VITE_APP_PRIVATE)) {
    deploy = modules[el].default;
  }
});
deploy = deploy[import.meta.env.VITE_APP_PRIVATE].global;

const microApps = [
  {
    name: 'customer',
    entry: import.meta.env.VITE_APP_ENTRY_Customer,
    container: '#subapp-viewport',
    activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/customer`,
    dsn: 'https://880b3f7b86c44e66b885a59e485a79e0@sentry.yunkecn.com/3',
    props: {
      globalWindow: window,
    },
  },
  {
    name: 'wechat',
    entry: import.meta.env.VITE_APP_ENTRY_Wechat,
    container: '#subapp-viewport',
    activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/wechat`,
    dsn: 'https://c9e63a9f94be43c4ba239abbfd0d3334@sentry.yunkecn.com/5',
  },
  {
    name: 'weixiaozhu',
    entry: import.meta.env.VITE_APP_ENTRY_Weixiaozhu,
    container: '#subapp-viewport',
    activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/wechatAssistantModules`,
    dsn: 'https://900d5b0070c74280a0ae6d541f3c3cbd@sentry.yunkecn.com/6',
  },
  {
    name: 'yunxiaozhi',
    entry: import.meta.env.VITE_APP_ENTRY_YunXiaoZhi,
    container: '#subapp-viewport',
    activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/yunxiaozhi`,
    dsn: 'https://900d5b0070c74280a0ae6d541f3c3cbd@sentry.yunkecn.com/6',
  },
];
if (import.meta.env.VITE_APP_PRIVATE !== 'yunke' && import.meta.env.VITE_APP_PRIVATE !== 'yongyou') { // 兼容私有部署 settings做为子应用加载
  microApps.push(...[
    {
      name: 'settings',
      entry: import.meta.env.VITE_APP_ENTRY_Settings,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/settings`,
      dsn: 'https://8fdbadb7734d4d5c9833cd93da860383@sentry.yunkecn.com/7',
    },
  ]);
}
if (import.meta.env.VITE_APP_PRIVATE === 'private') {
  microApps.push(...[
    {
      name: 'mall',
      entry: import.meta.env.VITE_APP_ENTRY_Mall,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/mall`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/10',
    },
  ]);
}
// 这几个只有yunke有
if (import.meta.env.VITE_APP_PRIVATE === 'yunke' || import.meta.env.VITE_APP_PRIVATE === 'yk2') {
  microApps.push(...[
    {
      name: 'enterpriseWeChat',
      entry: import.meta.env.VITE_APP_ENTRY_Qiyeweixin,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/enterpriseWeChat`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/8',
    },
    {
      name: 'mall',
      entry: import.meta.env.VITE_APP_ENTRY_Mall,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/mall`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/10',
    },
    {
      name: 'letssell',
      entry: ['crm2.yunkecn.com'].includes(window.location.origin) ? import.meta.env.VITE_APP_ENTRY_Letssell : `${window.location.origin}/webback/`,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/letssell`,
      dsn: 'https://2fa8fa79e7084304aa0fb87e3f40612b@sentry.yunkecn.com/9',
    },
    {
      name: 'remote',
      entry: import.meta.env.VITE_APP_ENTRY_Remote,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/remote`,
      dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
    },
    {
      name: 'scrm',
      entry: `${window.location.protocol}//${window.location.host.startsWith('test') || window.location.host.startsWith('qa') ? 'testqw' : 'qw'}${import.meta.env.VITE_APP_ENTRY_Scrm}`,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/scrm`,
      dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
      props: initGlobalState({
        fetchJsonp: async (url) => {
          const response = await FetchJsonp(url);
          if (response.ok) {
            return response.json();
          }
          return Promise.reject();
        },
      }),
    },
    // {
    //   name: 'cofco',
    //   entry: JSON.parse(localStorage.getItem('companyInfo'))?.companyDomain
    //     ? `${JSON.parse(localStorage.getItem
    // ('companyInfo'))?.companyDomain}` : '//localhost:3000',
    //   container: '#subapp-viewport',
    //   activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/cofco`,
    //   dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
    // },
  ]);
}
if (import.meta.env.VITE_APP_PRIVATE === 'xueqiu-beijing' || import.meta.env.VITE_APP_PRIVATE === 'hongsong') {
  microApps.push(...[
    {
      name: 'enterpriseWeChat',
      entry: import.meta.env.VITE_APP_ENTRY_Qiyeweixin,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/enterpriseWeChat`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/8',
    },
  ]);
}
if (import.meta.env.VITE_APP_PRIVATE === 'yongyou' || import.meta.env.VITE_APP_PRIVATE === 'zhongfangxinfu') {
  const URL = {
    yunke: 'https://mochat-scrm.yunkecn.com',
    zhongfangxinfu: 'https://scrm.zfxf.com',
  };
  microApps.push(...[
    {
      name: 'mcrm',
      entry: URL[import.meta.env.VITE_APP_PRIVATE] || import.meta.env.VITE_APP_ENTRY_MCRM,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/mcrm`,
      dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
    },
  ]);
}
if (import.meta.env.VITE_APP_PRIVATE === 'zhongliang') {
  microApps.push(...[
    {
      name: 'mall',
      entry: import.meta.env.VITE_APP_ENTRY_Mall,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/mall`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/10',
    },
    {
      name: 'cofco',
      entry: JSON.parse(localStorage.getItem('companyInfo'))?.companyDomain ? `${JSON.parse(localStorage.getItem('companyInfo'))?.companyDomain}${JSON.parse(localStorage.getItem('companyInfo'))?.jumpPathInfo}` : '//localhost:3000',
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/cofco`,
      dsn: 'https://086a6737449d459dba893cd1abebef8b@sentry.yunkecn.com/10',
    },
  ]);
}
if (import.meta.env.VITE_APP_PRIVATE === 'dongyi' || import.meta.env.VITE_APP_PRIVATE === 'zhongliang') {
  microApps.push(...[
    {
      name: 'scrm',
      entry: deploy.SCRM,
      container: '#subapp-viewport',
      activeRule: `${import.meta.env.VITE_APP_ROOT_BASE}/scrm`,
      dsn: 'https://873228f5a3a84ba59fb530c31058f3ed@sentry.yunkecn.com/10',
    },
  ]);
}
if (import.meta.env.NODE_ENV === 'production' && import.meta.env.VITE_APP_API_ENV === 'main' && import.meta.env.VITE_APP_PRIVATE !== 'lizihang' && import.meta.env.VITE_APP_PRIVATE !== 'chongqingnongshang' && import.meta.env.VITE_APP_PRIVATE !== 'zhongliang') {
  // 集成验证令牌 b59af9843d15486b80faa2587169ea0a76329c4c2f7545c0ac838fa0d60ebf15
  // const userData = localStorage.getItem('userData');
  // let user = {
  //   cellphone: null,
  //   realName: null,
  // };
  // if (userData) {
  //   const userObj = JSON.parse(userData);
  //   user = {
  //     cellphone: userObj.cellphone,
  //     realName: userObj.realName,
  //   };
  // }
  // const dsn = 'https://76f9e9643d304786835fcaa6d7c3958e@sentry.yunkecn.com/2';
  // 设置子应用的dsn
  // microApps.forEach((item) => {
  //   if (window.location.pathname.indexOf(item.activeRule) > -1) {
  //     if (item.dsn) {
  //       dsn = item.dsn;
  //     }
  //   }
  // });
  // Sentry.init({
  //   dsn,
  //   integrations: [
  //     new Integrations.BrowserTracing(),
  //   /*   new RewriteFramesIntegration(
  //       {
  //         iteratee: (frame1) => {
  //           const frame = frame1;
  //           frame.filename = frame.filename.replace(/^https?:\/\/.*\/cms/, '~/build');
  //           frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-customer/, '~/build');
  //           frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-wechat/, '~/build');
  //           frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-weixiaozhu/, '~/build');
  //           frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-qiyeweixin/, '~/build');
  //           frame.filename = frame.filename.replace(/^https?:\/\/.*\/sub-settings/, '~/build');
  //           return frame;
  //         },
  //       },
  //     ), */
  //   ],
  //   tracesSampleRate: 0.5,
  //   // environment: import.meta.env.VITE_APP_API_ENV,
  //   // ignoreErrors: [
  //   //   'Failed to fetch',
  //   //   'NetworkError when attempting to fetch resource.',
  //   //   'ResizeObserver loop limit exceeded',
  //   //   'ResizeObserver loop completed with undelivered notifications',
  //   // ],
  //   initialScope: {
  //     user,
  //   },
  //   /* beforeSend(event) {
  //     // 检查浏览器版本，如果过低，则不必报告错误
  //     let tooLow = false;
  //     // eslint-disable-next-line
  //     if (String.prototype.hasOwnProperty('matchAll')) {
  //       const edgeVersion = [...navigator.userAgent.matchAll(/edge\/(\d+)\./gmi)];
  //       if (edgeVersion.length > 0) {
  //         if (Number(edgeVersion[0][1]) < 37) {
  //           tooLow = true;
  //         }
  //       }
  //       const chromeVersion = [...navigator.userAgent.matchAll(/chrome\/(\d+)\./gmi)];
  //       if (chromeVersion.length > 0) {
  //         if (Number(chromeVersion[0][1]) < 70) {
  //           tooLow = true;
  //         }
  //       }
  //     } else {
  //       tooLow = true;
  //     }
  //     if (tooLow) return null;
  //     // 以下设置将打开一个用户界面供用户描述详细操作步骤，慎用
  //     //   if (event.exception) {
  //     //     Sentry.showReportDialog({ eventId: event.event_id });
  //     //   }
  //     return event;
  //   }, */
  // });
}
// eslint-disable-next-line no-console
console.log(
  `%c ${name}-${import.meta.env.VITE_APP_PRIVATE} %c v${version} %c`,
  'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
  'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
  'background:transparent',
);
dayjs.locale('zh-cn');
ReactDOM.render(<App />, document.getElementById('root'));

registerMicroApps(microApps);

// qiankun 通过fetch 请求百度地图 有跨域 问题 所以 过滤 百度地图使用 script 标签加载
start({
  excludeAssetFilter: (url) => {
    const whiteWords = ['baidu', 'map', 'z_stat', 'weixin.qq.com', 'cnzz', 'wxLogin', '/State/query'];
    return whiteWords.some((w) => url.includes(w));
  },
});

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`${window.location.origin}/service-worker.js`);
  }
  if ('Notification' in window) {
    Notification.requestPermission();
  }
});
