import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getDeviceExpireList, queryCompanyLabel } from '@/service/user-service';
import ExpireModal from '../expireModal';
import IpModal from '../ipModal';
import DocumentNotice from '../documentNotice';

const ModalList = () => {
  const loginUser = JSON.parse(localStorage.getItem('userData'));
  const [modalOrder, setModalOrder] = useState([]);
  const [currentModal, setCurrentModal] = useState('');
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const nowData = new Date().getTime();
  const companyInfo = localStorage.getItem('companyInfo') ? JSON.parse(localStorage.getItem('companyInfo')) : {};
  const openIpDate = companyInfo?.openIpDate
    ? new Date(companyInfo?.openIpDate).getTime()
    : 0;
  const now = dayjs();
  const twoMonthsLater = now.add(2, 'month');

  // 检查是否已经关闭过模态框
  const hasClosedModals = localStorage.getItem('hasClosedModals') === 'true';

  const loadData = async () => {
    if (loginUser?.userType !== 1) return;
    const dataParams = [];
    const response = await getDeviceExpireList({
      pageNum: 1,
      pageSize: 10,
    });
    if (response.success && response.data.deviceExpireList.length > 0) {
      dataParams.push('ExpireModal');
    }
    const res = await queryCompanyLabel({});
    if (res?.success) {
      const filterList = res?.data?.filter((e) => {
        const endTime = dayjs(e.endTime);
        return endTime.isBefore(twoMonthsLater); // 包括已经到期的数据
      }) || [];
      if (filterList.length > 0) {
        dataParams.push('DocumentNotice');
      }
    }
    if (nowData < openIpDate) {
      dataParams.push('IpModal');
    }

    setModalOrder(dataParams);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (hasClosedModals) {
      setCurrentModalIndex(modalOrder.length); // 设置为最后一个索引 + 1
    }
  }, [hasClosedModals]);

  useEffect(() => {
    if (modalOrder.length > 0 && currentModalIndex < modalOrder.length) {
      setCurrentModal(modalOrder[currentModalIndex]);
    } else {
      setCurrentModal('');
    }
  }, [modalOrder, currentModalIndex]);

  const handleClose = () => {
    if (currentModalIndex < modalOrder.length - 1) {
      setCurrentModalIndex(currentModalIndex + 1);
    } else {
      setCurrentModalIndex(modalOrder.length); // 设置为最后一个索引 + 1
      localStorage.setItem('hasClosedModals', 'true'); // 设置标志位
    }
  };

  return (
    <>
      {!hasClosedModals && currentModal === 'ExpireModal' && <ExpireModal onClose={handleClose} />}
      {!hasClosedModals && currentModal === 'DocumentNotice' && <DocumentNotice onClose={handleClose} />}
      {!hasClosedModals && currentModal === 'IpModal' && nowData < openIpDate && <IpModal onClose={handleClose} />}
    </>
  );
};

export default ModalList;
